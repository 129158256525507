import Vue from "vue";
import VueRouter from "vue-router";
import StoreLocator from "../components/StoreLocator.vue";
const { isNavigationFailure, NavigationFailureType } = VueRouter;

Vue.use(VueRouter);

const vueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return vueRouterPush.call(this, location).catch((err) => {
    // Only throw non-duplicate nav errors. Duplicate nav errors are okay to be ignored.
    if (!isNavigationFailure(err, NavigationFailureType.duplicated)) {
      console.error(err.name);
    }
  });
};

export const routes = [
  {
    name: "locator-home",
    path: "/",
    component: StoreLocator,
    children: [],
  },
  {
    name: "regional-npd-indexable-locator",
    path: "/npd/:placeUrlName/:productUrlName/",
    component: StoreLocator,
    children: [],
  },
  {
    name: "npd-page-locator",
    path: "/npd/:productUrlName/",
    component: StoreLocator,
    children: [],
  },
  {
    name: "info-page-locator",
    path: "/i/:slug/",
    component: StoreLocator,
    children: [],
  },
  {
    name: "business-detail",
    path: "/bd/:businessUrlName",
    component: StoreLocator,
    children: [],
  },
  {
    // Required to render the locator on a internal url only used to preview
    // changes on the page builder
    name: "business-detail-preview",
    path: "/page-templates/:pageTemplateId/bd/:businessUrlName/preview",
    component: StoreLocator,
    children: [],
  },
  // Placing "/:country/:state/:city" route after all the other fixed segment pattern routes
  // to ensure more specific path matching takes precedence over the more generic path matching.
  // This ordering is crucial because the routing mechanism matches routes based on their order
  // of definition. A URL that could potentially match both patterns (due to having a similar
  // segment structure) needs to be evaluated against the most specific route first to prevent
  // incorrect route matching.

  // By positioning the generic "/:country/:state/:city" route last, we create a "catch-all"
  // pattern for URLs that do not match any of the more specific routes defined earlier in the
  // configuration.

  // TODO: how should we handle this catch all route?
  {
    name: "indexable-locator-home",
    path: "/:country/:state/:city",
    component: StoreLocator,
    children: [],
  },
];

// set base URL relative to subfolder (ex. example.com/stores/locator/)
const scriptName = document.querySelector('meta[name="script-name"]').content;

// For info pages and nearby product detail pages, show the locator in the page instead of
// under the '/locator/' path like it does in the standalone page.
const locatorStandalone = !!document.getElementById("locator-standalone");

const url = new URL(window.location);
const isV2 = url.pathname.includes("v2");

const createRouter = (locatorStandalone) =>
  new VueRouter({
    mode: "history",
    // The `v2` path is utilized by the Template System feature during the development of a new page type.
    base: scriptName + (locatorStandalone ? "/locator/" : isV2 ? "/v2" : "/"),
    // The info page with an included vsl would jump to the top when searching, etc.
    // Keep it at last saved position for smoother ux.
    scrollBehavior: (to, from, savedPosition) =>
      locatorStandalone ? { y: 0 } : savedPosition,
    routes: routes,
  });

const router = createRouter(locatorStandalone);

export default router;
